// Piwik
window._paq = window._paq || [];
window._paq.push(["trackPageView"]);
window._paq.push(["enableLinkTracking"]);
(() => {
	const u = "https://piwik.kdo.de/";
	window._paq.push(["setTrackerUrl", `${u}piwik.php`]);
	window._paq.push(["setSiteId", 26]);
	const d = document;
	const g = d.createElement("script");
	const s = d.getElementsByTagName("script")[0];
	g.type = "text/javascript";
	g.async = true;
	g.defer = true;
	g.src = `${u}piwik.js`;
	s.parentNode.insertBefore(g, s);
})();
